import Time from './api/time';

import './App.css';

const URL = 'https://hello.kurkle.workers.dev/';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Time url={URL}  />
      </header>
    </div>
  );
}

export default App;
