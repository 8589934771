import React from 'react';

export default class Time extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            time: 0
        };
        this.update = this.update.bind(this);
    }
    async componentDidMount() {
        if (this.props.url) {
            const response = await fetch(this.props.url);
            if (response && response.ok) {
                this.setState(await response.json());
            }
        }
    }

    async update() {
        if (this.props.url) {
            const response = await fetch(this.props.url, {method: 'POST'});
            if (response && response.ok) {
                this.setState(await response.json());
            }
        }
    }

    render() {
        return (
            <div>
                Last update time: {new Date(this.state.time).toLocaleString()}<br/>
                <button onClick={this.update}>Update</button>
            </div>
        );
    }
}